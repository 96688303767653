import {SearchInput} from '../../components/better-select/better-select.component';
import {ViewItem} from '../../view-item';
import {Link} from '../../base/link';
import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class FormOptions {
    shareTypes: SearchInput = [{id: 'ISSUE', text: 'Issue'},
        {id: 'BOARD', text: 'Board'},
        {id: 'JQL', text: 'Filter'},
        {id: 'ROADMAP', text: 'Timeline'}];

    commentOptions: SearchInput = [{id: 'View', text: 'View'},
        {id: 'Add', text: 'Add'}];

    internalCommentOptions: SearchInput = [{id: 'View', text: 'View'},
        {id: 'Add', text: 'Add'}];

    linkedIssuesOptions: SearchInput = [{id: 'View', text: 'View basic info'},
        {id: 'ViewAll', text: 'View content'}];

    attachmentOptions: SearchInput = [{id: 'View', text: 'View'},
        {id: 'Add', text: 'Add'}];

    publicAttachmentOptions: SearchInput = [{id: 'Add', text: 'Add'}];

    subtaskOptions: SearchInput = [{id: 'View', text: 'View basic info'},
        {id: 'ViewAll', text: 'View content'}];

    statusOptions: SearchInput = [{id: 'Edit', text: 'Allow change'}];

    priorityOptions: SearchInput = [{id: 'Edit', text: 'Allow change'}];

    worklogOptions: SearchInput = [{id: 'View', text: 'View'}];

    weblinksOptions: SearchInput = [{id: 'View', text: 'View'}];

    changelogOptions: SearchInput = [{id: 'View', text: 'View'}];

    summaryOptions: SearchInput = [{id: 'Edit', text: 'Allow change'}];

    descriptionOptions: SearchInput = [{id: 'Edit', text: 'Allow change'}];

    issueCreationOptions: SearchInput = [{id: 'Allow', text: 'Allow'}];

    getCommentOptions(link: Link): ViewItem[] {
        const options: ViewItem[] = [];
        if (link.showComments) {
            options.push(...this.findOptions('View', this.commentOptions));
        }
        if (link.allowAddComment) {
            options.push(...this.findOptions('Add', this.commentOptions));
        }
        return options;
    }

    getInternalCommentOptions(link: Link): ViewItem[] {
        const options: ViewItem[] = [];
        if (link.showInternalComments) {
            options.push(...this.findOptions('View', this.internalCommentOptions));
        }
        if (link.allowAddInternalComment) {
            options.push(...this.findOptions('Add', this.internalCommentOptions));
        }
        return options;
    }

    getAttachmentOptions(link: Link): ViewItem[] {
        const options: ViewItem[] = [];
        if (link.showAttachments) {
            options.push(...this.findOptions('View', this.attachmentOptions));
        }
        if (link.allowAddAttachment) {
            options.push(...this.findOptions('Add', this.attachmentOptions));
        }
        return options;
    }

    getPublicAttachmentOptions(link: Link): ViewItem[] {
        const options: ViewItem[] = [];
        if (link.allowAddPublicAttachment) {
            options.push(...this.findOptions('Add', this.publicAttachmentOptions));
        }
        return options;
    }

    getLinkedIssuesOptions(link: Link): ViewItem[] {
        const options: ViewItem[] = [];
        if (link.showLinkedIssues) {
            options.push(...this.findOptions('View', this.linkedIssuesOptions));
        }
        if (link.shareLinkedIssuesContent) {
            options.push(...this.findOptions('ViewAll', this.linkedIssuesOptions));
        }
        return options;
    }

    getSubtaskOptions(link: Link): ViewItem[] {
        const options: ViewItem[] = [];
        if (link.showSubtasks) {
            options.push(...this.findOptions('View', this.subtaskOptions));
        }
        if (link.shareSubtasksContent) {
            options.push(...this.findOptions('ViewAll', this.subtaskOptions));
        }
        return options;
    }

    getStatusOptions(link: Link): ViewItem[] {
        if (link.allowWorkflowTransition) {
            return this.findOptions('Edit', this.statusOptions);
        }
        return [];
    }

    getPriorityOptions(link: Link): ViewItem[] {
        if (link.allowEditPriority) {
            return this.findOptions('Edit', this.priorityOptions);
        }
        return [];
    }

    getWorklogOptions(link: Link): ViewItem[] {
        if (link.showWorklog) {
            return this.findOptions('View', this.worklogOptions);
        }
        return [];
    }

    getChangelogOptions(link: Link): ViewItem[] {
        if (link.showChangelog) {
            return this.findOptions('View', this.changelogOptions);
        }
        return [];
    }

    getWeblinksOptions(link: Link): ViewItem[] {
        if (link.showWebLinks) {
            return this.findOptions('View', this.weblinksOptions);
        }
        return [];
    }

    getSummaryOptions(link: Link): ViewItem[] {
        if (link.allowEditSummary) {
            return this.findOptions('Edit', this.summaryOptions);
        }
        return [];
    }

    getDescriptionOptions(link: Link): ViewItem[] {
        if (link.allowEditDescription) {
            return this.findOptions('Edit', this.descriptionOptions);
        }
        return [];
    }

    getIssueCreationOptions(link: Link): ViewItem[] {
        if (link.allowCreateNewIssue) {
            return this.findOptions('Allow', this.issueCreationOptions);
        }
        return [];
    }

    findOptions(id: string, options: SearchInput): ViewItem[] {
        if (Array.isArray(options)) {
            return options.filter(option => option.id === id);
        }
        return [];
    }

    hasOption(form: FormGroup, controlName: string, optionId: string): boolean {
        const options = form.get(controlName)?.value as ViewItem[];
        return options?.some(option => option.id === optionId) ?? false;
    }
}
