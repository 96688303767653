import {Injectable, NgZone} from '@angular/core';
import {CustomField} from './base/custom-field';
import {EMPTY, from, Observable, of, Subject} from 'rxjs';
import {
    catchError,
    concatMap,
    expand,
    filter,
    map,
    mergeMap,
    reduce,
    shareReplay,
    switchMap,
    tap,
    toArray
} from 'rxjs/operators';
import {AtlassianUser} from './atlassian-user';
import {IssueType} from './issueType';
import {Board, Issue, IssueTypeFields} from './issue';
import {AppService} from './app.service';
import {ViewItem} from './view-item';
import {Content} from './base/content';
import {environment} from '../environments/environment';
import {IssueField} from './global-view/issue-layout-customization/types/issueField';
import {IssueTypeField} from './global-view/issue-layout-customization/types/issueType';
import APDialogOptions = AP.APDialogOptions;
import RequestOptions = AP.RequestOptions;
import {IssuesListFromJQL} from './components/activity/activityConfig';
import {ParsedJqlFromApi} from './ParsedJqlFromApi';
import Utils from './utils/utils';

export interface AvatarUrls {
    '48x48': string;
    '24x24': string;
    '16x16': string;
    '32x32': string;
}

export interface Project {
    entityId: string;
    expand: string;
    isPrivate: boolean;
    id: string;
    key: string;
    name: string;
    projectTypeKey: string;
    self: string;
    simplified: boolean;
    style: string;
    uuid: string;
    properties: any;
    issueTypes?: IssueType[];
    avatarUrls: AvatarUrls;
}

export interface ProjectRole {
    id: number;
    self: string;
    name: string;
    description: string;
    scope?: ProjectRoleScope;
}

export interface ProjectRoleScope {
    type: string;
    project: { id: string };
}

export interface RequestOpts {
    url?: string;
    type?: 'GET' | 'PUT' | 'POST' | 'DELETE';
    cache?: boolean;
    data?: string | {};
    contentType?: string;
    headers?: {};
    success?: (data: any) => void;
    error?: (xhr: XMLHttpRequest, statusText: string, errorThrown: any) => void;
    experimental?: boolean;
}

export interface ProjectSearchResponse {
    self: string;
    nextPage: string;
    maxResults: number;
    startAt: number;
    total: number;
    isLast: boolean;
    values: Array<Project>;
}

export interface BoardsResult {
    startAt: number;
    maxResults: number;
    total: number;
    isLast: boolean;
    values: Board[];
}

export interface Permissions {
    canCreate: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canSendEmail: boolean;
    canViewSharesInProject?: boolean;
}

export interface StatusResult {
    isLast: boolean;
    maxResults: number;
    nextPage: string;
    self: string;
    startAt: number;
    total: number;
    values: Status[];
}

export interface Status {
    description: string;
    statusCategory: string;
    id: string;
    name: string;
}

@Injectable({
    providedIn: 'root'
})
export class JiraService {

    public getIssueCached: (id: string) => Observable<Issue>;
    public getBoardCached: (id: string) => Observable<Board>;

    constructor(private zone: NgZone,
                private app: AppService) {
        this.getIssueCached = observableCache(this.getIssue.bind(this));
        this.getBoardCached = observableCache(this.getBoard.bind(this));
    }

    private static id() {
        return Math.random().toString(36).substr(2, 9);
    }

    getFieldsForFilterView(): Observable<ViewItem[]> {
        return this.apRequest(`/rest/api/3/field`).pipe(
            map(response => {
                let allTypes = JSON.parse(response);
                allTypes = allTypes.map(it => {
                    return {
                        id: it.key,
                        text: it.name
                    };
                }).filter(item =>  // we filter out these fields because there is no or no reasonable way to display this data
                    item.id !== 'issuelinks' &&
                    item.id !== 'subtasks' &&
                    item.id !== 'attachment' &&
                    item.id !== 'thumbnail' &&
                    item.id !== 'worklog' &&
                    item.text !== 'Work log' &&
                    item.id !== 'issuerestriction' &&
                    item.id !== 'comment'
                );
                // we need to remove duplicates with the same id because some fields might appear
                // multiple times because they are duplicated while creating new team managed project
                return removeDuplicatesById(allTypes);
            }),
        );

        function removeDuplicatesById(array: ViewItem[]) {
            const idSet = new Set();
            return array.filter(item => {
                if (idSet.has(item.id)) {
                    return false;
                }
                idSet.add(item.id);
                return true;
            });
        }
    }



    getAllIssueFields(): Observable<IssueField[]> {
        return this.apRequest('/rest/api/3/field').pipe(
            map(response => JSON.parse(response)
                .map(it => {
                    return {id: it.id, name: it.name, schema: it.schema ? it.schema : ''};
                })
            ),
        );
    }

    getFieldValuesFromIssue(issueId, fields) {
        if (!issueId) {
            return of({fields: {}, key: ''});
        }

        return this.apRequest('/rest/api/3/issue/' + issueId + '?fields=' + fields).pipe(
            map(response => JSON.parse(response)),
        );
    }

    getIssueTypesForGlobalProjects(): Observable<IssueTypeField[]> {
        return this.apRequest(`/rest/api/3/issuetype`).pipe(
            map(response => JSON.parse(response || '').filter(it => !it.scope))
        );
    }

    getAllFields(): Observable<IssueField[]> {
        return this.apRequest(`/rest/api/3/field`).pipe(
            map(response => JSON.parse(response))
        );
    }
    getCustomFieldsForProject(projectKeyOrId: string): Observable<CustomField[]> {
        return this.apRequest(`/rest/api/3/issue/createmeta?expand=projects.issuetypes.fields&projectIds=${projectKeyOrId}`).pipe(
            map(response => JSON.parse(response || '')),
            map(r => r || {}),
            map(r => r.projects || []),
            map(p => p.length > 0 ? p[0] : {}),
            map(it => it.issuetypes || []),
            map((issueTypes: IssueType[]) => {
                const typeFields = issueTypes.map(it => it.fields);
                return typeFields.reduce((ag, val) => Object.assign(ag, val), {});
            }),
            map((it: IssueTypeFields) => {
                return Object.entries(it)
                    .filter(([k, v]) => !!v.schema)
                    .filter(([k, v]) => !!v.schema.customId)
                    .map(([k, v]) => {
                        return {
                            id: v.schema.customId,
                            name: v.name
                        };
                    });
            })
        );
    }

    getCustomFieldsForPicker(projectKeyOrId: string): Observable<ViewItem[]> {
        return this.getCustomFieldsForProject(projectKeyOrId).pipe(
            map(cfs => cfs.map(cf => ({id: cf.id.toString(), text: cf.name})))
        );
    }

    getAddonProperty(propertyKey: string, defaultValue?: any) {
        const applicationKey = this.app.claims().iss;
        return this.apRequest(`/rest/atlassian-connect/1/addons/${applicationKey}/properties/${propertyKey}`, {
                type: 'GET'
            }
        ).pipe(
            map(it => it ? JSON.parse(it) : {}),
            map(it => it.value),
            catchError(it => {
                if (it && it.xhr && it.xhr.status === 404) {
                    return of({key: propertyKey, value: defaultValue});
                }
                throw it;
            }),
        );
    }

    getAllPriorityTypes() {
        return this.apRequest(`/rest/api/2/priority`, {
                type: 'GET',
                contentType: 'application/json',
            }
        ).pipe(
            map(it => JSON.parse(it)),
        );
    }

    setAddonProperty(propertyKey: string, data: any) {
        const applicationKey = this.app.claims().iss;
        return this.apRequest(`/rest/atlassian-connect/1/addons/${applicationKey}/properties/${propertyKey}`, {
                type: 'PUT',
                contentType: 'application/json',
                data: JSON.stringify(data)
            }
        ).pipe(
            map(it => JSON.parse(it)),
        );
    }

    setProjectProperty(projectIdOrKey: string, propertyKey: string, data: any) {
        const applicationKey = this.app.claims().iss;
        const shortKey = applicationKey.endsWith('-lite') ? 'jesl' : 'jes';
        return this.apRequest(`/rest/api/3/project/${projectIdOrKey}/properties/${shortKey}-${propertyKey}`, {
                type: 'PUT',
                contentType: 'application/json',
                data: JSON.stringify(data)
            }
        );
    }

    getProjectProperty(projectIdOrKey: string, propertyKey: string, defaultValue?: any) {
        const applicationKey = this.app.claims().iss;
        const shortKey = applicationKey.endsWith('-lite') ? 'jesl' : 'jes';
        return this.apRequest(`/rest/api/3/project/${projectIdOrKey}/properties/${shortKey}-${propertyKey}`, {
                type: 'GET',
            }
        ).pipe(
            map(it => {
                return it ? JSON.parse(it) : {};
            }),
            catchError(it => {
                if (it && it.xhr && it.xhr.status === 404) {
                    return of({key: propertyKey, value: defaultValue});
                }
                throw it;
            }),
            map(it => it.value)
        );
    }
    getContext(): Observable<JiraContext> {
        return from(new Promise<JiraContext>((resolve, reject) => {
            AP.context.getContext()
                .then(
                    (it: JiraContext) => {
                        this.zone.run(() => resolve(it));
                    },
                    it => {
                        this.zone.run(() => reject(it));
                    },
                );
        }));
    }

    getContent(contentId: any): Observable<Content> {
        return this.apRequest(`/rest/api/content/${contentId}?expand=body.view,container`)
            .pipe(map(it => JSON.parse(it)));
    }

    getCustomData<T>(): Observable<T> {
        if (window.__cache_custom_data) {
            return of(window.__cache_custom_data);
        }

        return from(new Promise<T>((resolve) => {
            AP.dialog.getCustomData(it => this.zone.run(() => {
                window.__cache_custom_data = it;
                return resolve(it as T);
            }));
        }));
    }

    setCustomData(data: any) {
        window.__cache_custom_data = data;
    }

    getCurrentUser(): Observable<UserContext> {
        return from(new Promise<UserContext>((resolve) => {
            AP.user.getCurrentUser(user => {
                resolve(user);
            });
        }));
    }

    getUser(): Observable<AtlassianUser> {
        return this.getCurrentUser()
            .pipe(
                switchMap(it => Utils.require(this.requestUser(it.atlassianAccountId),
                    'JiraService', 'getUser', `requestUser(${it.atlassianAccountId})`))
            );
    }

    issuePicker(term: string, projectId?: string): Observable<Issue[]> {
        const jql = `ORDER BY priority DESC, updated DESC`;

        const queryParams = new URLSearchParams({
            query: encodeURIComponent(term),
            currentJQL: jql,
            currentProjectId: projectId
        });

        return this.apRequest('/rest/api/3/issue/picker?' + queryParams.toString()).pipe(
            map(it => {
                return this.flattenAndRemoveDuplicates(JSON.parse(it).sections);
            })
        );
    }

    verifySingleJql(jql: string): Observable<ParsedJqlFromApi> {
        const body = {
            queries: [
                jql
            ]
        };

        return this.apRequest('/rest/api/3/jql/parse?validation=strict',
            {
                type: 'POST',
            contentType: 'application/json',
            data: JSON.stringify(body)
            }).pipe(
            map(it => {
                return JSON.parse(it).queries[0];
            })
        );
    }

    flattenAndRemoveDuplicates(arr) {
        const flat = [];
        const uniqueIssues = new Map<string, Issue>();

        for (const array of arr) {
            for (const issue of array.issues) {
                if (!uniqueIssues.has(issue.id)) {
                    uniqueIssues.set(issue.id, issue);
                    flat.push(issue);
                }
            }
        }
        return flat;
    }

    getProject(idOrKey: string): Observable<Project> {
        return this.apRequest(`/rest/api/3/project/${idOrKey}`).pipe(
            map(it => JSON.parse(it))
        );
    }

    getAllTeamManagedProjects() {
        return this.getAllTeamManagedProjectsRecursive('/rest/api/3/project/search?expand=issueTypes&maxResults=50', 0);
    }

    getAllTeamManagedProjectsRecursive(url: string, page: number): Observable<Project[]> {
        return this.apRequest(`${url}&startAt=${page * 50}`).pipe(
            mergeMap(response => {
                const parsedResponse: ProjectSearchResponse = JSON.parse(response);
                const projects: Project[] = parsedResponse.values.filter(it => it.style === 'next-gen');
                const isNextPage = !parsedResponse.isLast;
                if (isNextPage) {
                    // If there's a next page, recursively fetch projects
                    return this.getAllTeamManagedProjectsRecursive(url, page + 1).pipe(
                        map(nextPageProjects => projects.concat(nextPageProjects))
                    );
                } else {
                    // If there's no next page, return the projects
                    return of(projects);
                }
            })
        );
    }

    getSearchedProjectsWithIssueTypes(query: string): Observable<ProjectSearchResponse> {
        return this.apRequest(`/rest/api/3/project/search?query=${query}&expand=issueTypes`).pipe(
            map(it => JSON.parse(it))
        );
    }

    searchProjects(query: string): Observable<ProjectSearchResponse> {
        return this.apRequest(`/rest/api/3/project/search?query=${query}`).pipe(
            map(it => JSON.parse(it))
        );
    }

    requestUser(atlassianAccountId: string): Observable<AtlassianUser> {
        if (window['__cache_user_' + atlassianAccountId]) {
            return of(window['__cache_user_' + atlassianAccountId]);
        }
        return this.apRequest('/rest/api/3/user?accountId=' + atlassianAccountId)
            .pipe(
                map(it => JSON.parse(it)),
                tap(it => window['__cache_user_' + it.accountId] = it)
            );
    }

    searchForIssue(query: string,
                   projectId?: string,
                   showSubTasks: boolean = true,
                   showSubTaskParent: boolean = false) {
        const q =
            `query=${query}` +
            `&showSubTasks=${showSubTasks}` +
            `&showSubTaskParent=${showSubTaskParent}` +
            (projectId ? `&currentProjectId=${projectId}` : '');

        return this.apRequest('/rest/api/3/issue/picker?' + q)
            .pipe(
                map(it => JSON.parse(it).sections[0])
            );
    }

    searchForIssues(jql: string): Observable<IssuesListFromJQL> {
        return this.apRequest('/rest/api/3/search?jql=' + jql)
            .pipe(
                map(it => JSON.parse(it))
            );
    }

    checkIssueCountForProvidedJql(jql: string): Observable<number> {
        return this.apRequest('/rest/api/3/search?maxResults=10000&jql=' + jql)
            .pipe(
                map(it => {
                    return JSON.parse(it).total;
                })
            );
    }

    searchForUser(query: string) {
        const q = `query=${query}`;
        return this.apRequest('/rest/api/3/user/picker?' + q)
            .pipe(
                map(it => JSON.parse(it))
            );
    }

    searchForGroup(query: string) {
        const q = `query=${query}`;
        return this.apRequest('/rest/api/3/groups/picker?' + q)
            .pipe(
                map(it => JSON.parse(it))
            );
    }

    searchForRole(): Observable<ProjectRole[]> {
        return this.apRequest('/rest/api/3/role')
            .pipe(
                map(it => JSON.parse(it))
            );
    }

    /**
     * For server we will fetch all roles
     */
    searchForRoleForProject(projectIdOrKey: string): Observable<ProjectRole[]> {
        return this.apRequest(`/rest/api/3/project/${projectIdOrKey}/roledetails`)
            .pipe(
                map(it => JSON.parse(it))
            );
    }

    searchForProject(query: string): Observable<ProjectSearchResponse> {
        const q = `query=${query}`;
        return this.apRequest('/rest/api/3/project/search?' + q)
            .pipe(
                map(it => JSON.parse(it))
            );
    }

    fetchUsersMap(userIds: Set<string>) {
        return from(userIds).pipe(
            filter(userId => this.notEmpty(userId)),
            concatMap(userId => this.requestUser(userId)),
            toArray(),
            map(users => {
                const userMap = new Map();
                users.forEach((atlassianUser: AtlassianUser) => {
                    userMap.set(atlassianUser.accountId, atlassianUser);
                });
                return userMap;
            }),
        );
    }

    notEmpty<T>(value: T | null | undefined): value is T {
        return value !== null && value !== undefined;
    }

    getIssue(issue: string): Observable<Issue> {
        return this.apRequest('/rest/api/3/issue/' + issue)
            .pipe(map(it => JSON.parse(it)));
    }

    getBoard(id: string): Observable<Board> {
        return this.apRequest('/rest/agile/1.0/board/' + id)
            .pipe(map(it => JSON.parse(it)));
    }

    public getAllBoards(projectIdOrKey: string): Observable<Board[]> {
        return this.getBoards(projectIdOrKey, 0).pipe(
            expand(result => {
                if (result.isLast) {
                    return EMPTY;
                }
                return this.getBoards(projectIdOrKey, result.startAt + result.maxResults);
            }),
            map(result => result.values),
            reduce((acc, values) => acc.concat(values), [])
        );
    }

    getBoards(projectIdOrKey: string, startAt: number): Observable<BoardsResult> {
        const query = `?startAt=${startAt}&projectLocation=${projectIdOrKey}`;
        return this.apRequest(`/rest/agile/1.0/board${query}`)
            .pipe(map(response => JSON.parse(response)));
    }

    getStatuses(projectIdOrKey: string, term: string): Observable<StatusResult> {
        const queryParams = new URLSearchParams({
            projectId: projectIdOrKey,
            searchString: encodeURIComponent(term)
        });
        return this.apRequest(`/rest/api/3/statuses/search?` + queryParams.toString())
            .pipe(map(response => JSON.parse(response)));
    }

    getBaseUrl(): Observable<any> {
        return of(environment.host);
    }

    getLocation(): Observable<URL> {
        return from(new Promise<URL>((resolve) => {
            AP.getLocation(it => this.zone.run(() => {
                return resolve(new URL(it));
            }));
        }));
    }

    showDialog(parameters: APDialogOptions, callback?: (result?: any) => void) {
        parameters.customData = parameters.customData || {};
        parameters.customData.jwtToken = window.getToken().token;
        parameters.customData.features = window.getToken().features;
        parameters.customData.closeEventId = 'dialog-id-' + JiraService.id();

        AP.events.once(parameters.customData.closeEventId, result => {
            if (callback) {
                callback(result ? JSON.parse(result) : result);
            }
        });

        AP.dialog.create(parameters);
    }

    // noinspection JSMethodCanBeStatic
    closeDialog(data?: object) {
        this.getCustomData<any>().subscribe(it => {
            if (it) {
                if (it.closeEventId) {
                    AP.events.emit(it.closeEventId, JSON.stringify(data));
                }
            }
            AP.dialog.close();
        });
    }

    // noinspection JSMethodCanBeStatic
    emitEvent(name: string, ...args: string[]) {
        AP.events.emit(name, ...args);
    }

    // noinspection JSMethodCanBeStatic
    onEvent(eventName: string, listener: (...data: any[]) => void) {
        const zone = this.zone;
        AP.events.on(eventName, function eventZone() {
            const args = arguments;
            zone.run(() => {
                listener(...Array.from(args));
            });
        });
    }

    // noinspection JSMethodCanBeStatic
    onEventOnce(eventName: string, listener: (...data: any[]) => void) {
        const zone = this.zone;
        AP.events.once(eventName, function eventZone() {
            const args = arguments;
            zone.run(() => {
                listener(...Array.from(args));
            });
        });
    }

    observeEvent(eventName: string): Observable<any> {
        const subject = new Subject();
        this.onEvent(eventName, (data) => {
            subject.next(data);
        });
        return subject;
    }

    evalExpression(expression: string): Observable<any> {
        return this.apRequest(`/rest/api/3/expression/eval`, {
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify({
                    expression
                })
            }
        ).pipe(
            map(it => JSON.parse(it))
        );
    }

    resize(elementId: string): void {
        sizeWatcher(elementId);
    }

    private apRequest(url: string, opts: RequestOpts = {}): Observable<any> {
        return from(new Promise((resolve, reject) => {
            const success = (response: string) => {
                this.zone.run(() => {
                    resolve(response);
                });
            };

            const error = (xhr: XMLHttpRequest, statusText: string, errorThrown: any) => {
                this.zone.run(() => reject({xhr, statusText, errorThrown, url, opts}));
            };

            AP.request({
                url, success, error, ...opts
            } as RequestOptions);
        }));
    }
}

let cachedSensor: ResizeSensor = null;

function sizeWatcher(elementId: string) {
    if (cachedSensor) {
        cachedSensor.detach();
        cachedSensor = null;
    }
    const element = document.getElementById(elementId);
    let lastHeight = 0;
    let delayedResize: any = null;

    return cachedSensor = new ResizeSensor(element, () => {
        const style = getComputedStyle(element);
        const offsetHeight = element.offsetHeight + parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);

        if (offsetHeight < lastHeight) {
            if (delayedResize) {
                clearTimeout(delayedResize);
            }
            delayedResize = setTimeout(() => {
                AP.resize(null, offsetHeight + 'px');
                lastHeight = offsetHeight;
            }, 2000);
        } else {
            AP.resize(null, offsetHeight + 'px');
            if (delayedResize) {
                clearTimeout(delayedResize);
                delayedResize = null;
            }
            lastHeight = offsetHeight;
        }
    });
}

function observableCache(action: (arg: string) => Observable<any>) {
    const cache = {};
    return (arg) => {
        const cached = cache[arg];
        if (cached) {
            return cached;
        }
        atlas.log('Cache miss', arg, cache);
        return cache[arg] = action(arg)
            .pipe(shareReplay(1));
    };
}
