import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-multi-icon',
    template: `
        <ng-container [ngSwitch]="icon">
            <app-icon *ngSwitchCase="'attachment'" [class.no-hover]="!hover" appAttachmentIcon></app-icon>
            <app-icon *ngSwitchCase="'calendar'" [class.no-hover]="!hover" appCalendarIcon></app-icon>
            <app-icon *ngSwitchCase="'changelog'" [class.no-hover]="!hover" appChangelogIcon></app-icon>
            <app-icon *ngSwitchCase="'check'" [class.no-hover]="!hover" appCheckIcon></app-icon>
            <app-icon *ngSwitchCase="'close'" [class.no-hover]="!hover" appCloseIcon></app-icon>
            <app-icon *ngSwitchCase="'code'" [class.no-hover]="!hover" appCodeIcon></app-icon>
            <app-icon *ngSwitchCase="'comment'" [class.no-hover]="!hover" appCommentIcon></app-icon>
            <app-icon *ngSwitchCase="'copy'" [class.no-hover]="!hover" appCopyIcon></app-icon>
            <app-icon *ngSwitchCase="'customizeColumns'" [class.no-hover]="!hover" appCustomizeColumnsIcon></app-icon>
            <app-icon *ngSwitchCase="'dashboard'" [class.no-hover]="!hover" appDashboardIcon></app-icon>
            <app-icon *ngSwitchCase="'delete'" [class.no-hover]="!hover" appDeleteIcon></app-icon>
            <app-icon *ngSwitchCase="'edit'" [class.no-hover]="!hover" appEditIcon></app-icon>
            <app-icon *ngSwitchCase="'editFilled'" [class.no-hover]="!hover" appEditFilledIcon></app-icon>
            <app-icon *ngSwitchCase="'email'" [class.no-hover]="!hover" appEmailIcon></app-icon>
            <app-icon *ngSwitchCase="'error'" [class.no-hover]="!hover" appErrorIcon></app-icon>
            <app-icon *ngSwitchCase="'expiration'" [class.no-hover]="!hover" appExpirationIcon></app-icon>
            <app-icon *ngSwitchCase="'graphLine'" [class.no-hover]="!hover" appGraphLineIcon></app-icon>
            <app-icon *ngSwitchCase="'info'" [class.no-hover]="!hover" appInfoIcon></app-icon>
            <app-icon *ngSwitchCase="'issue'" [class.no-hover]="!hover" appInfoIcon></app-icon>
            <app-icon *ngSwitchCase="'issue-raise'" [class.no-hover]="!hover" appIssueRaiseIcon></app-icon>
            <app-icon *ngSwitchCase="'label'" [class.no-hover]="!hover" appLabelIcon></app-icon>
            <app-icon *ngSwitchCase="'link'" [class.no-hover]="!hover" appLinkIcon></app-icon>
            <app-icon *ngSwitchCase="'lockFull'" [class.no-hover]="!hover" appLockFullIcon></app-icon>
            <app-icon *ngSwitchCase="'lock'" [class.no-hover]="!hover" appLockIcon></app-icon>
            <app-icon *ngSwitchCase="'minus'" [class.no-hover]="!hover" appMinusIcon></app-icon>
            <app-icon *ngSwitchCase="'preferences'" [class.no-hover]="!hover" appPreferencesIcon></app-icon>
            <app-icon *ngSwitchCase="'search'" [class.no-hover]="!hover" appSearchIcon></app-icon>
            <app-icon *ngSwitchCase="'settings'" [class.no-hover]="!hover" appSettingsIcon></app-icon>
            <app-icon *ngSwitchCase="'shortcut'" [class.no-hover]="!hover" appShortcutIcon></app-icon>
            <app-icon *ngSwitchCase="'starFilled'" [class.no-hover]="!hover" appStarFilledIcon></app-icon>
            <app-icon *ngSwitchCase="'subtask'" [class.no-hover]="!hover" appSubtaskIcon></app-icon>
            <app-icon *ngSwitchCase="'quote'" [class.no-hover]="!hover" appQuoteIcon></app-icon>
            <app-icon *ngSwitchCase="'transition'" [class.no-hover]="!hover" appTransitionIcon></app-icon>
            <app-icon *ngSwitchCase="'arrowUp'" [class.no-hover]="!hover" appArrowUpIcon></app-icon>
            <app-icon *ngSwitchCase="'users'" [class.no-hover]="!hover" appUsersIcon></app-icon>
            <app-icon *ngSwitchCase="'view'" [class.no-hover]="!hover" appViewIcon></app-icon>
            <app-icon *ngSwitchCase="'warn'" [class.no-hover]="!hover" appWarnIcon></app-icon>
            <app-icon *ngSwitchCase="'worklog'" [class.no-hover]="!hover" appWorklogIcon></app-icon>
            <app-icon *ngSwitchDefault [class.no-hover]="!hover"></app-icon>
        </ng-container>
    `
})
export class MultiIconComponent {
    @Input() icon: IconType;
    @Input() hover = false;
}

export type IconType =
    'attachment' |
    'calendar' |
    'changelog' |
    'check' |
    'close' |
    'code' |
    'comment' |
    'copy' |
    'customizeColumns' |
    'dashboard' |
    'delete' |
    'edit' |
    'editFilled' |
    'email' |
    'error' |
    'expiration' |
    'graphLine' |
    'info' |
    'issue' |
    'issue-raise' |
    'label' |
    'link' |
    'lockFull' |
    'lock' |
    'minus' |
    'preferences' |
    'search' |
    'settings' |
    'shortcut' |
    'starFilled' |
    'subtask' |
    'quote' |
    'transition' |
    'arrowUp' |
    'users' |
    'view' |
    'warn' |
    'worklog';
