import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {debounceTime, distinctUntilChanged, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {combineLatest, defer, forkJoin, Observable, of, zip} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ViewItem} from '../view-item';
import {Link} from '../base/link';
import {SearchFunction} from '../components/better-select/better-select.component';
import {JiraService, Permissions, Project, ProjectSearchResponse, Status, StatusResult} from '../jira.service';
import {AppService} from '../app.service';
import {ItemService} from '../base/item.service';
import {ShareActionService} from '../share-action.service';
import Utils from '../utils/utils';
import PermissionUtils from '../utils/permission-utils';
import {CUSTOM_BOARD_ID, CustomBoardConfig} from './custom-board-config';
import CustomValidators from '../utils/custom-validators';
import {FormOptions} from './services/form-options.service';
import {domainValidator} from './share-form-components/domain-validator';
import {jqlValidator} from './share-form-components/jql-validator';
import {ParentRestrictionsService} from './services/parent-restrictions';

@Component({
    selector: 'app-item-form',
    templateUrl: './share-form.component.html',
    styleUrls: ['./share-form.component.css']
})
export class ShareFormComponent implements OnInit {
    update$ = of(new Link());
    uuid: string;
    availableCustomFields: ViewItem[];
    availableIssueLayouts: ViewItem[];
    availableCardLayouts: ViewItem[];
    permissions: Permissions;
    link: Link;
    availableGroupsAndRoles: ViewItem[];
    allFilterFields: ViewItem[];
    url: string;

    showForm = false;
    saveIndicator = false;
    saveMark = false;

    viewState = {
        showJQL: false,
        showCustomContentTab: false,
        showIssuePicker: false,
        showBoardPicker: false,
        isCustomBoard: false,
        showFilterColumns: false,
        isPasswordInputVisible: false,
        isExpirationInputVisible: false,
        isSelectedUsersVisible: false,
        isCustomHeaderVisible: false,
        isCustomFooterVisible: false,
        isCommentsWithRestrictionsVisible: false,
        isPasswordSecured: false,
        isSamlLoginRequired: false,
        tab: 'sharePreview'
    };

    form: FormGroup;

    constructor(private service: ItemService,
                private fb: FormBuilder,
                private jira: JiraService,
                private route: ActivatedRoute,
                private app: AppService,
                private actions: ShareActionService,
                private router: Router,
                protected formOptions: FormOptions,
                protected parentRestrictions: ParentRestrictionsService) {

        this.form = this.fb.group({
            name: ['', [Validators.required, Validators.maxLength(300)]],
            description: ['', [Validators.maxLength(4096)]],
            publicLink: [false],
            shareEnabled: [true],
            project: ['', Validators.required],
            projectKey: [''],
            type: ['', Validators.required],
            issue: ['', Validators.required],
            issueKey: [''],
            board: ['', Validators.required],
            jql: ['', [Validators.required], [jqlValidator(this.jira)]],
            filterShareFields: [[]],
            customBoardConfig: this.fb.group({
                boardName: [''],
                columnsConfig: this.fb.array([])
            }),

            comments: [[]],
            internalComments: [[]],
            attachments: [[]],
            publicAttachments: [[]],
            linkedIssues: [[]],
            subtasks: [[]],
            status: [[]],
            priority: [[]],
            worklog: [[]],
            changelog: [[]],
            summary: [[]],
            descriptionOpts: [[]],
            issueCreationOpts: [[]],

            passwordRequired: [false],
            password: ['', Validators.required],
            unlockSecret: [{value: '', disabled: true}],
            samlLoginRequired: [false],
            expirationRequired: [false],
            expiration: [''],
            allowSelectedUsers: false,
            selectedUsers: [[]],
            selectedDomains: ['', [domainValidator()]],
            allowCommentsWithRestrictions: false,
            groupsAndRoles: [[]],
            customFields: [[]],

            showCustomHeader: false,
            customHeaderText: [''],
            showCustomFooter: false,
            customFooterText: [''],
            issueLayout: [''],
            cardLayout: [''],

            weblinks: [[]],
            showSmartChecklist: false,
            showIssueChecklist: false
        });
    }

    ngOnInit() {
        this.initializeFormViewStateUpdater();
        this.initializeFormData();
    }

    private initializeFormViewStateUpdater() {
        this.updateViewState(this.form, 'type', this.showJQLInput.bind(this, this.form), 'showJQL');
        this.updateViewState(this.form, 'type', this.isNotIssueType.bind(this, this.form), 'showCustomContentTab');
        this.updateViewState(this.form, 'type', this.showIssuePicker.bind(this, this.form), 'showIssuePicker');
        this.updateViewState(this.form, 'type', this.showBoardPicker.bind(this, this.form), 'showBoardPicker');
        this.updateViewState(this.form, 'type', this.showFilterColumns.bind(this, this.form), 'showFilterColumns');
        this.updateViewState(this.form, 'type', this.showAdditionalConfiguration.bind(this, this.form), 'tab');
        this.updateViewState(this.form, 'board', this.isCustomBoard.bind(this, this.form), 'isCustomBoard');
        this.updateViewState(this.form, 'board', this.showJQLInput.bind(this, this.form), 'showJQL');
        this.updateViewState(this.form, 'passwordRequired',
            this.handleCheckboxToggle.bind(this, this.form, 'passwordRequired'), 'isPasswordInputVisible');
        this.updateViewState(this.form, 'passwordRequired',
            this.handleCheckboxToggle.bind(this, this.form, 'passwordRequired'), 'isPasswordSecured');
        this.updateViewState(this.form, 'samlLoginRequired',
            this.handleCheckboxToggle.bind(this, this.form, 'samlLoginRequired'), 'isSamlLoginRequired');
        this.updateViewState(this.form, 'expirationRequired',
            this.handleCheckboxToggle.bind(this, this.form, 'expirationRequired'), 'isExpirationInputVisible');
        this.updateViewState(this.form, 'allowSelectedUsers',
            this.handleCheckboxToggle.bind(this, this.form, 'allowSelectedUsers'), 'isSelectedUsersVisible');
        this.updateViewState(this.form, 'allowCommentsWithRestrictions',
            this.handleCheckboxToggle.bind(this, this.form, 'allowCommentsWithRestrictions'), 'isCommentsWithRestrictionsVisible');
        this.updateViewState(this.form, 'showCustomHeader',
            this.handleCheckboxToggle.bind(this, this.form, 'showCustomHeader'), 'isCustomHeaderVisible');
        this.updateViewState(this.form, 'showCustomFooter',
            this.handleCheckboxToggle.bind(this, this.form, 'showCustomFooter'), 'isCustomFooterVisible');
    }

    private initializeFormData() {
        this.prepareInitialData()
            .subscribe(({link, project, issue, boardCardLayoutList, issueLayoutsList, isNew, permissionsData, filterFields}) => {
                this.link = link;
                this.permissions = permissionsData;
                this.availableCustomFields = link.availableCustomFields;
                this.availableCardLayouts = this.getAvailableLayouts(boardCardLayoutList);
                this.availableIssueLayouts = this.getAvailableLayouts(issueLayoutsList);
                this.availableGroupsAndRoles = link.availableGroupsAndRoles;
                this.allFilterFields = filterFields;
                const currentCardLayoutSelection = this.availableCardLayouts
                    .filter(value => value.id === link.cardLayoutId)[0];
                const currentIssueLayoutSelection = this.availableIssueLayouts
                    .filter(value => value.id === link.issueLayoutId)[0];

                this.form.patchValue({
                    name: link.name,
                    description: link.description,
                    publicLink: link.namedShareLinkEnabled,
                    publicLinkUrl: link.linkName,
                    shareEnabled: link.sharedLinkEnabled,
                    project: {
                        id: project.id, text: `[${project.key}] ${project.name}`,
                        imageUrl: project.avatarUrls['16x16']
                    },
                    projectKey: link.projectKey,
                    type: {id: link.type, text: ''},
                    issue: link.issueId ? {id: link.issueId, text: `${link.issueKey} ${issue.fields.summary}`} : null,
                    issueKey: link.issueKey,
                    board: link.board,
                    jql: link.jql,
                    filterShareFields: link.filterShareFields,

                    comments: this.formOptions.getCommentOptions(link),
                    internalComments: this.formOptions.getInternalCommentOptions(link),
                    attachments: this.formOptions.getAttachmentOptions(link),
                    publicAttachments: this.formOptions.getPublicAttachmentOptions(link),
                    linkedIssues: this.formOptions.getLinkedIssuesOptions(link),
                    subtasks: this.formOptions.getSubtaskOptions(link),
                    status: this.formOptions.getStatusOptions(link),
                    priority: this.formOptions.getPriorityOptions(link),
                    worklog: this.formOptions.getWorklogOptions(link),
                    changelog: this.formOptions.getChangelogOptions(link),
                    weblinks: this.formOptions.getWeblinksOptions(link),
                    summary: this.formOptions.getSummaryOptions(link),
                    descriptionOpts: this.formOptions.getDescriptionOptions(link),
                    issueCreationOpts: this.formOptions.getIssueCreationOptions(link),

                    passwordRequired: !!link.password,
                    password: link.password,
                    unlockSecret: link.unlockSecret,
                    expirationRequired: !!link.expiration,
                    expiration: link.expiration,
                    samlLoginRequired: link.samlLoginRequired,
                    allowSelectedUsers: link.selectedUsersConfig.allowed,
                    selectedUsers: link.selectedUsersConfig.list.map(item => item.email).join(','),
                    selectedDomains: link.selectedUsersConfig.domains,
                    allowCommentsWithRestrictions: link.allowCommentsWithRestrictions,
                    groupsAndRoles: link.groupsAndRoles,
                    customFields: link.customFields,
                    showCustomFooter: link.showCustomFooter,
                    showCustomHeader: link.showCustomHeader,
                    customHeaderText: link.customHeaderText,
                    customFooterText: link.customFooterText,
                    issueLayout: currentIssueLayoutSelection ? currentIssueLayoutSelection : {id: '', text: ''},
                    cardLayout: currentCardLayoutSelection ? currentCardLayoutSelection : {id: '', text: ''},
                    showSmartChecklist: link.showSmartChecklist,
                    showIssueChecklist: link.showIssueChecklist
                }, {emitEvent: false});

                this.setCustomBoardConfigForm(link);
                this.setStartingViewState(link);
                this.updateUrl();
                this.parentRestrictions.init(link);
                this.parentRestrictions.calculateRestrictionsInfo(this.form);

                this.showForm = true;

                if (!permissionsData.canEdit) {
                    this.form.disable();
                } else {
                    this.setFieldsAutoupdate();
                    this.setUrlAutoupdate();
                    this.form.valueChanges.pipe(debounceTime(300)).subscribe(() => {
                        this.parentRestrictions.calculateRestrictionsInfo(this.form);
                    });

                    combineLatest([
                        this.form.valueChanges.pipe(distinctUntilChanged()),
                        this.form.statusChanges.pipe(distinctUntilChanged())
                    ])
                        .pipe(
                            debounceTime(1200),
                            map(([form, status]) => ({form, status}))
                        )
                        .subscribe(() => {
                            this.saveForm();
                        });
                }
            });
    }

    private setStartingViewState(link: Link) {
        const formGroup = this.form;
        this.viewState = {
            showJQL: this.showJQLInput(formGroup),
            showCustomContentTab: this.isNotIssueType(formGroup),
            showBoardPicker: this.showBoardPicker(formGroup),
            showIssuePicker: this.showIssuePicker(formGroup),
            isCustomBoard: this.isCustomBoard(formGroup),
            showFilterColumns: this.showFilterColumns(formGroup),
            isSelectedUsersVisible: link.selectedUsersConfig.allowed,
            isPasswordInputVisible: !!link.password,
            isExpirationInputVisible: !!link.expiration,
            isCustomFooterVisible: link.showCustomFooter,
            isCustomHeaderVisible: link.showCustomHeader,
            isCommentsWithRestrictionsVisible: link.allowCommentsWithRestrictions,
            isPasswordSecured: !!link.password,
            isSamlLoginRequired: link.samlLoginRequired,
            tab: 'sharePreview'
        };
    }

    private prepareInitialData() {
        return this.jira.getCustomData().pipe(
            switchMap((it) => zip(
                Utils.require(this.createOrLoadShareData(it), 'ShareFormComponent', 'prepareInitialData', 'this.createOrLoadShareData'),
                Utils.require(Utils.combineParams(this.route), 'ShareFormComponent', 'prepareInitialData', 'Utils.combineParams'),
                Utils.require(this.jira.getAddonProperty('permissions', '{"enabled": false}'),
                    'ShareFormComponent', 'prepareInitialData', 'jira.getAddonProperty'))),
            switchMap(([data, params, permissionSchemeObj]) => {
                const uuid = data.uuid;
                this.uuid = uuid;
                const isNewShare = data.isNew === 'true';
                const permissions = PermissionUtils.getPermissionsFromCustomData(
                    data,
                    PermissionUtils.getPermissionsObj(params, permissionSchemeObj.enabled)
                );
                return zip(
                    Utils.require(this.service.get(uuid), 'ShareFormComponent', 'prepareInitialData', `service.get(${uuid})`),
                    of(isNewShare),
                    of(permissions)
                ).pipe(
                    map(([link, isNew, permissionsData]) => ({link, isNew, permissionsData}))
                );
            }),
            switchMap(({link, isNew, permissionsData}) => {
                const projectObs = Utils.require(this.jira.getProject(link.projectId),
                    'ShareFormComponent', 'prepareInitialData', `this.jira.getProject(${link.projectId})`);
                const issueObs = link.issueId ? Utils.require(this.jira.getIssue(link.issueId),
                    'ShareFormComponent', 'prepareInitialData', `this.jira.getIssue(${link.issueId})`) : of(null);
                const boardCardLayoutsObs = Utils.require(this.app.getBoardCardLayoutsList(),
                    'ShareFormComponent', 'prepareInitialData', `this.app.getBoardCardLayoutsList()`);
                const issueLayoutsObs = Utils.require(this.app.getIssueLayoutsList(),
                    'ShareFormComponent', 'prepareInitialData', `this.app.getIssueLayoutsList()`);
                const filterFieldsObs = Utils.require(this.jira.getFieldsForFilterView(),
                    'ShareFormComponent', 'prepareInitialData', `this.jira.getFieldsForFilterView()`);

                return forkJoin([projectObs, issueObs, boardCardLayoutsObs, issueLayoutsObs, filterFieldsObs]).pipe(
                    map(([project, issue, boardCardLayoutList, issueLayoutsList, filterFields]) => ({
                        link, project, issue, boardCardLayoutList, issueLayoutsList, isNew, permissionsData, filterFields
                    }))
                );
            })
        );
    }

    private getAvailableLayouts(layoutsList: { id?: string, name: string }[]): ViewItem[] {
        return layoutsList.map(it => {
            return {
                id: it.id,
                text: it.name
            };
        });
    }

    updateFormOnChange(form: FormGroup, whatChanged: string[], whatUpdate: string, newValueProducer: Observable<any>) {
        const updateControl = form.get(whatUpdate);
        whatChanged.map(id => form.get(id))
            .forEach(control => {
                control.valueChanges.pipe(
                    switchMap(value => {
                        return newValueProducer;
                    })
                ).subscribe(it => {
                    if (it) {
                        updateControl.setValue(it);
                        updateControl.updateValueAndValidity();
                    }
                });
            });
    }

    setFieldsAutoupdate() {
        this.updateFormOnChange(this.form, ['projectKey', 'type'],
            'jql',
            defer(() => of(this.getDefaultJQL())));

        this.updateFormOnChange(this.form, ['project'],
            'projectKey',
            defer(() => this.getProjectKey()));

        this.updateFormOnChange(this.form, ['project', 'type'],
            'board',
            of({id: CUSTOM_BOARD_ID, text: 'Custom Board'}));

        this.updateFormOnChange(this.form, ['project', 'type'],
            'issue',
            defer(() => this.getFirstIssueInProject()));

        this.updateFormOnChange(this.form, ['issue'],
            'issueKey',
            defer(() => this.getIssueKey()));

        this.updateFormOnChange(this.form, ['passwordRequired'],
            'password',
            defer(() => of(this.getPassword())));

        this.updateFormOnChange(this.form, ['type', 'project', 'board'],
            'customBoardConfig',
            defer(() => this.setCustomDefaultBoardConfig()));
    }

    private setUrlAutoupdate() {
        this.form.get('publicLink').valueChanges.subscribe(() => this.updateUrl());
        this.form.get('name').valueChanges.subscribe(() => {
            if (this.form.get('publicLink').value) {
                this.updateUrl();
            }
        });
    }

    updateViewState(form: FormGroup, whatChanged: string, newValueProducer: (value: any) => boolean, viewField: string) {
        const updateControl = form.get(whatChanged);
        updateControl.valueChanges.subscribe(value => {
            this.viewState[viewField] = newValueProducer(value);
        });
    }

    createOrLoadShareData(customData) {
        // "Create External Share Link" from hamburger menu "..." in jira cloud
        // We get issueId in URL. Lets create new share from that.
        customData = customData || {};
        if (!customData.uuid) {
            return this.route.queryParams.pipe(
                switchMap((it: Params) => {
                        return Utils.require(this.service.create(it.issueId),
                            'ShareFormComponent', 'createOrLoadShareData', `this.service.create(${it.issueId})`);
                    }
                ),
                map(it => ({uuid: it.uuid, isNew: true})),
                tap(it => {
                    this.jira.setCustomData(Object.assign({uuid: it.uuid}, customData));
                })
            );
        }
        return of(customData);
    }

    issuePicker: SearchFunction = (term) => {
        const projectId = this.form.get('project').value?.id;

        if (!projectId) {
            return of();
        }

        return this.jira.issuePicker(`${term}`, projectId).pipe(
            map(it => ({
                results: it.map(p => ({id: p.id, text: p.key + ' ' + p.summaryText})),
                pagination: {more: false}
            }))
        );
    }

    projectPicker: SearchFunction = (term) => {
        return this.jira.searchProjects(term).pipe(
            map((response: ProjectSearchResponse) => ({
                results: response.values.map((p: Project) => ({
                    id: p.id,
                    text: `[${p.key}] ${p.name}`,
                    imageUrl: p.avatarUrls['16x16']
                })),
                pagination: {
                    more: false
                }
            }))
        );
    }

    boardPicker: SearchFunction = (term) => {
        const projectId = this.form.get('project').value?.id;

        if (!projectId) {
            return of();
        }

        return this.jira.getAllBoards(projectId).pipe(
            map(boards => {
                const filteredBoards = term && term.length > 0
                    ? boards.filter(b => b.name.toLowerCase().includes(term.toLowerCase()))
                    : boards;

                const customBoard = {id: CUSTOM_BOARD_ID, text: 'Custom Board'};
                const resultsWithCustom = [customBoard,
                    ...filteredBoards.map(b => ({id: b.id, text: b.name}))];
                return {
                    results: resultsWithCustom,
                    pagination: {more: false}
                };
            })
        );
    }

    statusPicker: SearchFunction = (term) => {
        return this.jira.getStatuses(this.form.get('project').value?.id, term).pipe(
            map((statuses: StatusResult) => ({
                    results: statuses.values.map((s: Status) => ({
                        id: s.id,
                        text: s.name,
                    })),
                    pagination: {
                        more: false
                    }
                })
            ));
    }

    customFieldPicker: SearchFunction = (term) => {
        return of(this.availableCustomFields).pipe(
            map(item => ({
                results: item
                    .filter((s) =>
                        !term ||
                        s.id.toLowerCase().includes(term.toLowerCase()) ||
                        s.text.toLowerCase().includes(term.toLowerCase())
                    )
                    .map((s) => ({
                        id: s.id,
                        text: s.text,
                    }))
            }))
        );
    }

    groupAndRolesPicker: SearchFunction = (_) => {
        return of(this.availableGroupsAndRoles).pipe(
            map(item => ({
                    results: item.map((s) => ({
                        id: s.id,
                        text: s.text,
                    }))
                })
            ));
    }

    cardLayoutPicker: SearchFunction = (_) => {
        return of(this.availableCardLayouts).pipe(
            map(item => ({
                    results: item.map((s) => ({
                        id: s.id,
                        text: s.text,
                    }))
                })
            ));
    }

    issueLayoutPicker: SearchFunction = (_) => {
        return of(this.availableIssueLayouts).pipe(
            map(item => ({
                    results: item.map((s) => ({
                        id: s.id,
                        text: s.text,
                    }))
                })
            ));
    }

    getDefaultJQL(): string {
        const projectKey = this.form.get('projectKey').value;
        const type = this.form.get('type').value.id;
        if (!projectKey || !type) {
            return '';
        }

        let jqlQuery = `project = "${projectKey}"`;

        if (type === 'JQL') {
            jqlQuery += ' order by created DESC';
        } else if (type === 'BOARD') {
            jqlQuery += ' order by Rank ASC';
        } else {
            jqlQuery += ' and issuetype = EPIC order by Rank ASC';
        }
        return jqlQuery;
    }

    getProjectKey(): Observable<string> {
        return this.jira.getProject(this.form.get('project').value?.id).pipe(
            map((project: Project) => project.key)
        );
    }

    getFirstIssueInProject(): Observable<ViewItem> {
        const projectId = this.form.get('project').value?.id;

        if (!projectId) {
            return of();
        }

        return this.jira.issuePicker('', projectId).pipe(
            map(it => it.length > 0 ? {id: it[0].id, text: it[0].key + ' ' + it[0].summaryText} : {id: '', text: ''})
        );
    }

    getIssueKey(): Observable<string> {
        const issueId = this.form.get('issue').value?.id;

        if (!issueId) {
            return of();
        }

        return this.jira.getIssue(issueId).pipe(
            map(it => it.key)
        );
    }

    getPassword(): string {
        const value = this.form.get('password').value;
        if (value) {
            return value;
        }
        return Utils.generateRandomString(8);
    }

    showIssuePicker(form: FormGroup): boolean {
        return form.get('type').value.id === 'ISSUE';
    }

    showBoardPicker(form: FormGroup): boolean {
        return form.get('type').value.id === 'BOARD';
    }

    showJQLInput(form: FormGroup): boolean {
        const type = form.get('type').value.id;
        return type === 'JQL' || type === 'ROADMAP' || this.isCustomBoard(form);
    }

    isNotIssueType(form: FormGroup): boolean {
        const type = form.get('type').value.id;
        return type !== 'ISSUE';
    }

    showAdditionalConfiguration(form: FormGroup): string {
        const type = form.get('type').value.id;
        if (type === 'JQL') {
            return 'filterColumns';
        }
        if (this.isCustomBoard(form)) {
            return 'boardConfig';
        }
        return 'sharePreview';
    }

    showFilterColumns(form: FormGroup): boolean {
        const type = form.get('type').value.id;
        return type === 'JQL';
    }

    isCustomBoard(form: FormGroup): boolean {
        const type = form.get('type').value.id;

        if (type !== 'BOARD') {
            return false;
        }
        const boardId = form.get('board').value?.id;

        return CUSTOM_BOARD_ID === boardId;
    }

    handleCheckboxToggle(form: FormGroup, controlName: string) {
        return form.get(controlName).value;
    }

    saveForm() {
        this.saveMark = false;
        this.saveIndicator = true;
        const link = this.getLink();

        this.update$ = this.actions.update(link).pipe(
            map(() => {
                this.jira.emitEvent('share-update', JSON.stringify(link));

                this.saveIndicator = false;
                this.saveMark = true;

                setTimeout(() => {
                    this.saveMark = false;
                }, 1500);

                this.updateUrl();

                return link;
            }),
        );
    }

    private getLink(): Link {
        return this.updateLinkFromForm(this.link, this.form);
    }

    private updateLinkFromForm(link: Link, form: FormGroup): Link {
        // General
        link.name = form.get('name')?.value;
        link.description = form.get('description')?.value;
        link.namedShareLinkEnabled = form.get('publicLink')?.value;
        link.linkName = form.get('publicLinkUrl')?.value;
        link.sharedLinkEnabled = form.get('shareEnabled')?.value;
        link.projectId = form.get('project')?.value.id;
        link.projectKey = form.get('projectKey')?.value;
        link.issueId = form.get('issue')?.value?.id;
        link.type = form.get('type')?.value?.id;
        link.issueKey = form.get('issueKey')?.value;
        link.board = form.get('board')?.value;
        link.jql = form.get('jql')?.value;
        link.filterShareFields = form.get('filterShareFields')?.value;
        link.customBoardConfig = form.get('customBoardConfig')?.value;

        // Security
        link.password = form.get('passwordRequired').value ? form.get('password')?.value : '';
        link.unlockSecret = form.get('unlockSecret')?.value;
        link.expiration = form.get('expirationRequired')?.value ? form.get('expiration')?.value : '';
        link.passwordRequired = form.get('passwordRequired').value;
        link.samlLoginRequired = form.get('samlLoginRequired')?.value;
        link.selectedUsersConfig = {
            ...link.selectedUsersConfig,
            allowed: form.get('allowSelectedUsers')?.value,
            list: form.get('selectedUsers')?.value ?
                form.get('selectedUsers')?.value.split(',').map((email: string) => ({
                    email: email.trim(),
                    type: 'TO'
                })) : [],
            domains: form.get('selectedDomains')?.value
        };
        link.allowCommentsWithRestrictions = form.get('allowCommentsWithRestrictions')?.value;
        link.groupsAndRoles = form.get('groupsAndRoles')?.value;

        // Permissions
        link.showComments = this.formOptions.hasOption(form, 'comments', 'View');
        link.allowAddComment = this.formOptions.hasOption(form, 'comments', 'Add');
        link.showInternalComments = this.formOptions.hasOption(form, 'internalComments', 'View');
        link.allowAddInternalComment = this.formOptions.hasOption(form, 'internalComments', 'Add');
        link.showAttachments = this.formOptions.hasOption(form, 'attachments', 'View');
        link.allowAddAttachment = this.formOptions.hasOption(form, 'attachments', 'Add');
        link.allowAddPublicAttachment = this.formOptions.hasOption(form, 'publicAttachments', 'Add');
        link.showLinkedIssues = this.formOptions.hasOption(form, 'linkedIssues', 'View');
        link.shareLinkedIssuesContent = this.formOptions.hasOption(form, 'linkedIssues', 'ViewAll');
        link.showSubtasks = this.formOptions.hasOption(form, 'subtasks', 'View');
        link.shareSubtasksContent = this.formOptions.hasOption(form, 'subtasks', 'ViewAll');
        link.allowWorkflowTransition = this.formOptions.hasOption(form, 'status', 'Edit');
        link.allowEditPriority = this.formOptions.hasOption(form, 'priority', 'Edit');
        link.showWorklog = this.formOptions.hasOption(form, 'worklog', 'View');
        link.showChangelog = this.formOptions.hasOption(form, 'changelog', 'View');
        link.allowEditSummary = this.formOptions.hasOption(form, 'summary', 'Edit');
        link.allowEditDescription = this.formOptions.hasOption(form, 'descriptionOpts', 'Edit');
        link.allowCreateNewIssue = this.formOptions.hasOption(form, 'issueCreationOpts', 'Allow');

        // Layout
        link.customFields = form.get('customFields')?.value;
        link.showCustomFooter = form.get('showCustomFooter')?.value;
        link.showCustomHeader = form.get('showCustomHeader')?.value;
        link.customHeaderText = form.get('customHeaderText')?.value;
        link.customFooterText = form.get('customFooterText')?.value;
        link.issueLayoutId = form.get('issueLayout')?.value?.id;
        link.cardLayoutId = form.get('cardLayout')?.value?.id;

        // Extensions
        link.showWebLinks = this.formOptions.hasOption(form, 'weblinks', 'View');
        link.showIssueChecklist = this.form.get('showIssueChecklist')?.value;
        link.showSmartChecklist = this.form.get('showSmartChecklist')?.value;

        return link;
    }

    confirmDeletion() {
        this.actions.deleteSingle(this.getLink()).then(({deleted}) => {
            if (deleted) {
                this.jira.closeDialog();
            }
        });
    }

    close() {
        this.jira.closeDialog();
    }

    isSaving(): boolean {
        return this.saveIndicator;
    }

    sendViaEmail(canSendEmail: boolean) {
        const link = this.getLink();
        this.saveMark = false;
        this.saveIndicator = true;
        this.actions.update(link).subscribe((result) => {
            this.jira.emitEvent('share-update', JSON.stringify(link));
            this.saveIndicator = false;
            this.saveMark = true;
            if (canSendEmail && (!!result || this.form.disabled)) {
                this.router.navigate(['share', 'mail'], {queryParamsHandling: 'merge'});
            }
        });
    }

    updateUrl(): void {
        const publicLink = this.form.get('publicLink').value;
        const baseShareUrl = this.link.baseShareUrl;
        const id = this.link.id;
        const uuid = this.uuid;
        const name = this.form.get('name').value;

        if (publicLink) {
            this.url = `${baseShareUrl}${id}/${Utils.prepareLinkFriendlyName(name)}`;
        } else {
            this.url = `${baseShareUrl}${uuid}`;
        }
    }

    changeTab(tab: string) {
        this.viewState.tab = tab;
    }

    private setCustomBoardConfigForm(link: Link) {
        const customBoardConfig = link.customBoardConfig ? {
            boardName: link.customBoardConfig.boardName,
            columnsConfig: this.populateColumnsConfig(link.customBoardConfig)
        } : {boardName: '', columnsConfig: []};

        this.form.get('customBoardConfig.boardName').setValue(customBoardConfig.boardName);

        const columnsConfigArray = this.form.get('customBoardConfig.columnsConfig') as FormArray;
        columnsConfigArray.clear();
        customBoardConfig.columnsConfig.forEach(group => {
            columnsConfigArray.push(group);
        });
        this.form.get('customBoardConfig').setValidators(CustomValidators.customBoardConfigValidator);
        this.form.get('customBoardConfig').updateValueAndValidity();
    }

    private populateColumnsConfig(config: CustomBoardConfig): FormGroup[] {
        const columnsControl: FormGroup[] = [];
        config.columnsConfig.forEach(column => {
            columnsControl.push(this.fb.group({
                name: [column.name],
                statuses: [column.statuses]
            }));
        });
        return columnsControl;
    }

    public createFormGroupsFromStatuses(statuses: Status[]): FormGroup[] {
        const categoryNames = new Map<string, string>([
            ['TODO', 'To Do'],
            ['IN_PROGRESS', 'In Progress'],
            ['DONE', 'Done']
        ]);

        const columnsControl: FormGroup[] = [];

        Array.from(categoryNames.entries()).forEach(([key, name]) => {
            const filteredStatuses = statuses.filter(s => s.statusCategory === key);
            const customFields = filteredStatuses.map(s => ({id: s.id, text: s.name}));
            columnsControl.push(this.fb.group({
                name: [name],
                statuses: [customFields]
            }));
        });

        return columnsControl;
    }

    setCustomDefaultBoardConfig() {
        const projectId = this.form.get('project')?.value.id;
        this.jira.getStatuses(projectId, '').subscribe(statusResult => {
            const statuses = statusResult.values;
            const boardColumns = this.createFormGroupsFromStatuses(statuses);
            const columnsConfigArray = this.form.get('customBoardConfig.columnsConfig') as FormArray;
            columnsConfigArray.clear();
            boardColumns.forEach(group => {
                columnsConfigArray.push(group);
            });
            this.form.get('customBoardConfig.boardName').setValue('External Share Custom Board');
        });
    }
}
