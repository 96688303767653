import {Component, OnInit, ViewChild} from '@angular/core';
import {delay, map, shareReplay, tap} from 'rxjs/operators';
import {JiraService} from '../../jira.service';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {ShareActionService} from '../../share-action.service';
import {Observable, zip} from 'rxjs';
import Utils from '../../utils/utils';
import {ActivatedRoute} from '@angular/router';
import {AppService, GlobalSettings} from '../../app.service';
import {GlobalSettingsService} from './global-settings.service';
import {CustomDomainComponent} from './components/custom-domain/custom-domain.component';
import CustomValidators from '../../utils/custom-validators';
import {LoaderService} from '../../loader.service';
import {PageCustomizationComponent} from './components/page-customization/page-customization.component';

export interface GlobalViewSettingsComponentConfig {
    settings: GlobalSettings;
    permissionEnabled: boolean;
    isLite: boolean;
}

@Component({
    selector: 'app-global-view-settings',
    templateUrl: './global-view-settings.component.html',
    styleUrls: ['./global-view-settings.component.css']
})
export class GlobalViewSettingsComponent implements OnInit {
    features = window.getToken().features;

    defaultRelayState;
    assertionConsumerUrl;
    issuerId;

    form = new FormGroup({
        enableJiraPermissions: new FormControl(false),
        allowedIPs: new FormControl('', [CustomValidators.validateIP()]),

        emailTitle: new FormControl({value: null, disabled: true}, Validators.required),
        emailContent: new FormControl({value: null, disabled: true}, Validators.required),
        emailTemplate: new FormControl({value: null, disabled: true}, Validators.required),

        emailCustom: new FormControl(false),
        emailHost: new FormControl({value: '', disabled: true}, Validators.required),
        emailPort: new FormControl({value: '', disabled: true}, Validators.required),
        emailUsername: new FormControl({value: '', disabled: true}, Validators.required),
        emailPassword: new FormControl({value: '', disabled: true}),
        emailFrom: new FormControl({value: '', disabled: true}),
        emailPersonalName: new FormControl({value: '', disabled: true}),

        enableCustomDomain: new FormControl(false),
        enableDarkMode: new FormControl(false),
        customHeader: new FormControl(''),
        customHeaderColor: new FormControl({value: '', disabled: true}),
        customHeaderColorDarkMode: new FormControl({value: '', disabled: true}),
        customHeaderBackgroundColor: new FormControl({value: '', disabled: true}),
        customHeaderBackgroundColorDarkMode: new FormControl({value: '', disabled: true}),
        customHeaderLocationSelect: new FormControl(''),
        customHeaderLinks: new FormControl([]),
        showBackgroundImage: new FormControl(false),
        customBodyBackgroundColor: new FormControl({value: '', disabled: true}),
        customBodyBackgroundColorDarkMode: new FormControl({value: '', disabled: true}),
        customBodyBackgroundImage: new FormControl({value: '', disabled: true}),
        customBodyBackgroundImageDarkMode: new FormControl({value: '', disabled: true}),
        customFooter: new FormControl(''),
        customFooterColor: new FormControl({value: '', disabled: true}),
        customFooterColorDarkMode: new FormControl({value: '', disabled: true}),
        customFooterBackgroundColor: new FormControl({value: '', disabled: true}),
        customFooterBackgroundColorDarkMode: new FormControl({value: '', disabled: true}),
        customFooterLinks: new FormControl([]),
        hideHeaderLinks: new FormControl(false),
        showHeader: new FormControl(true),
        showFooter: new FormControl(false),
        showSubscribe: new FormControl(true),
        analyticsID: new FormControl(''),
        emailCarrier: new FormControl(''),
        senderEmail: new FormControl(''),
        logo: new FormControl({
            value: '',
            disabled: true
        }, [CustomValidators.requiredFileType(['png', 'jpeg', 'jpg']), CustomValidators.maximumFileSize(524288)]),
        logoDark: new FormControl({
            value: '',
            disabled: true
        }, [CustomValidators.requiredFileType(['png', 'jpeg', 'jpg']), CustomValidators.maximumFileSize(524288)]),
        favicon: new FormControl({
            value: '',
            disabled: true
        }, [CustomValidators.requiredFileType(['png', 'jpeg', 'jpg']), CustomValidators.maximumFileSize(35840)]),
        customDomain: new FormControl({value: 'jira.external-share.com', disabled: true}),
        customCsp: new FormControl(''),
        sameSiteCookieRestriction: new FormControl(''),

        samlEnabled: new FormControl(false),
        loginUrl: new FormControl({value: '', disabled: true}, Validators.required),
        identifier: new FormControl({value: '', disabled: true}, Validators.required),
        workspace: new FormControl({value: '', disabled: true}, [Validators.required, Validators.minLength(3)]
            , [CustomValidators.validateWorkspace(this.app)]),
        identityProvider: new FormControl({value: '', disabled: true}),
        logoutUrl: new FormControl(''),
        certificate: new FormControl({value: '', disabled: true}, Validators.required),
        enableSmartChecklist: new FormControl(false),
        enableIssueChecklist: new FormControl(false)
    });

    $config: Observable<GlobalViewSettingsComponentConfig>;

    @ViewChild('customDomainComponent') customDomainComponent: CustomDomainComponent;
    @ViewChild(PageCustomizationComponent) pageCustomizationComponent!: PageCustomizationComponent;

    tab = 'permissions';
    samlConfigTab = 'config';

    sameSiteCookieNames = {
        LAX: 'Lax',
        NONE: 'None'
    };
    currentProvider = this.sameSiteCookieNames.LAX;

    constructor(private jira: JiraService,
                private app: AppService,
                private shareActions: ShareActionService,
                private route: ActivatedRoute,
                private globalSettings: GlobalSettingsService,
                private loader: LoaderService) {
    }

    ngOnInit() {
        this.$config = zip(
            this.app.settings(),
            this.jira.getAddonProperty('permissions', '{"enabled": false}'),
            this.route.parent.queryParamMap).pipe(
            map(([settings, permission, queryMap]) => {
                return {
                    settings,
                    permissionEnabled: permission.enabled,
                    isLite: Utils.isLiteApp(queryMap)
                };
            }),
            shareReplay(1)
        );

        this.$config.pipe(
            delay(100), // needed for multi-select
            tap(({settings, permissionEnabled, isLite}) => {
                this.defaultRelayState = settings.samlConfig.defaultRelayState;
                this.assertionConsumerUrl = settings.samlConfig.assertionConsumerUrl;
                this.issuerId = settings.samlConfig.issuerId;

                this.setState(
                    [
                        this.form.get('allowedIPs'),
                        this.form.get('emailCustom'),
                        this.form.get('emailTitle'),
                        this.form.get('emailContent'),
                        this.form.get('emailTemplate'),
                        this.form.get('enableCustomDomain'),
                        this.form.get('enableDarkMode'),
                        this.form.get('customHeader'),
                        this.form.get('customHeaderColor'),
                        this.form.get('customHeaderColorDarkMode'),
                        this.form.get('customHeaderBackgroundColor'),
                        this.form.get('customHeaderBackgroundColorDarkMode'),
                        this.form.get('customHeaderLocationSelect'),
                        this.form.get('customHeaderLinks'),
                        this.form.get('showBackgroundImage'),
                        this.form.get('customBodyBackgroundColor'),
                        this.form.get('customBodyBackgroundColorDarkMode'),
                        this.form.get('customBodyBackgroundImage'),
                        this.form.get('customBodyBackgroundImageDarkMode'),
                        this.form.get('customFooter'),
                        this.form.get('customFooterColor'),
                        this.form.get('customFooterColorDarkMode'),
                        this.form.get('customFooterBackgroundColor'),
                        this.form.get('customFooterBackgroundColorDarkMode'),
                        this.form.get('customFooterLinks'),
                        this.form.get('hideHeaderLinks'),
                        this.form.get('showHeader'),
                        this.form.get('showFooter'),
                        this.form.get('showSubscribe'),
                        this.form.get('analyticsID'),
                        this.form.get('removeDirectLinks'),
                        this.form.get('customDomain'),
                        this.form.get('customCsp'),
                        this.form.get('sameSiteCookieRestriction'),
                        this.form.get('logo'),
                        this.form.get('logoDark'),
                        this.form.get('favicon'),
                        this.form.get('samlEnabled'),
                        this.form.get('loginUrl'),
                        this.form.get('identifier'),
                        this.form.get('logoutUrl'),
                        this.form.get('certificate'),
                        this.form.get('workspace'),
                        this.form.get('identityProvider'),
                        this.form.get('enableSmartChecklist'),
                        this.form.get('enableIssueChecklist'),
                        this.form.get('emailCarrier'),
                        this.form.get('senderEmail'),
                    ],
                    true
                );
                this.form.get('samlEnabled').valueChanges.subscribe(
                    (v) =>
                        this.setState([
                            this.form.get('loginUrl'),
                            this.form.get('identifier'),
                            this.form.get('logoutUrl'),
                            this.form.get('certificate'),
                            this.form.get('workspace'),
                            this.form.get('identityProvider'),
                        ], v)
                );

                this.form.get('enableCustomDomain').valueChanges.subscribe(
                    (v) =>
                        this.setState([
                            this.form.get('customDomain'),
                        ], v)
                );

                this.form.patchValue({
                    ...settings,
                    enableJiraPermissions: permissionEnabled,
                    allowedIPs: settings.allowedIPs,
                    emailCustom: settings.emailSettings.custom,
                    emailHost: settings.emailSettings.host,
                    emailPort: settings.emailSettings.port,
                    emailUsername: settings.emailSettings.username,
                    emailFrom: settings.emailSettings.from,
                    emailPersonalName: settings.emailSettings.personalName,
                    emailPassword: settings.emailSettings.password,
                    enableCustomDomain: settings.customDomain.enabled,
                    enableDarkMode: settings.pageSettings.darkModeEnabled,
                    hideHeaderLinks: settings.pageSettings.pageHeader.hideHeaderLinks,
                    showHeader: settings.pageSettings.pageHeader.showHeader,
                    showSubscribe: settings.pageSettings.showSubscribe,
                    customHeader: settings.pageSettings.pageHeader.customHeader,
                    customHeaderColor: settings.pageSettings.pageHeader.color,
                    customHeaderColorDarkMode: settings.pageSettings.pageHeader.colorDarkMode,
                    customHeaderBackgroundColor: settings.pageSettings.pageHeader.backgroundColor,
                    customHeaderBackgroundColorDarkMode: settings.pageSettings.pageHeader.backgroundColorDarkMode,
                    customHeaderLocationSelect: settings.pageSettings.pageHeader.locationSelect,
                    customHeaderLinks: settings.pageSettings.pageHeader.customHeaderLinks,
                    showBackgroundImage: settings.pageSettings.customBody.showBackgroundImage,
                    customBodyBackgroundColor: settings.pageSettings.customBody.backgroundColor,
                    customBodyBackgroundColorDarkMode: settings.pageSettings.customBody.backgroundColorDarkMode,
                    customBodyBackgroundImage: settings.pageSettings.customBody.backgroundImage,
                    customBodyBackgroundImageDarkMode: settings.pageSettings.customBody.backgroundImageDarkMode,
                    showFooter: settings.pageSettings.pageFooter.showFooter,
                    customFooter: settings.pageSettings.pageFooter.customFooter,
                    customFooterColor: settings.pageSettings.pageFooter.color,
                    customFooterColorDarkMode: settings.pageSettings.pageFooter.colorDarkMode,
                    customFooterBackgroundColor: settings.pageSettings.pageFooter.backgroundColor,
                    customFooterBackgroundColorDarkMode: settings.pageSettings.pageFooter.backgroundColorDarkMode,
                    customFooterLinks: settings.pageSettings.pageFooter.customFooterLinks,
                    logo: Utils.modelToFile(settings.pageSettings.logo),
                    logoDark: Utils.modelToFile(settings.pageSettings.logoDark),
                    favicon: Utils.modelToFile(settings.pageSettings.favicon),
                    analyticsID: settings.pageSettings.analyticsID,
                    customDomain: settings.customDomain.domain,
                    customCsp: settings.customCsp,
                    sameSiteCookieRestriction: settings.sameSiteCookieRestriction ? settings.sameSiteCookieRestriction : 'LAX',
                    emailTitle: settings.emailTemplate.title,
                    emailContent: settings.emailTemplate.content,
                    emailTemplate: settings.emailTemplate.template,
                    samlEnabled: settings.samlConfig.samlEnabled,
                    loginUrl: settings.samlConfig.loginUrl,
                    identifier: settings.samlConfig.identifier,
                    logoutUrl: settings.samlConfig.logoutUrl,
                    certificate: settings.samlConfig.certificate,
                    workspace: settings.samlConfig.workspace,
                    identityProvider: settings.samlConfig.identityProvider ? settings.samlConfig.identityProvider : 'OTHER',
                    enableSmartChecklist: settings.integrations.smartCheckList,
                    enableIssueChecklist: settings.integrations.issueChecklist,
                    emailCarrier: settings.emailSettings.carrier,
                    senderEmail: settings.emailSettings.senderEmail,
                });

                this.form.get('enableJiraPermissions').valueChanges.subscribe((enabled) => {
                        this.jira.setAddonProperty('permissions', {enabled});
                    }
                );

                const enableCustomDomainControl = this.form.get('enableCustomDomain');
                const customDomainControl = this.form.get('customDomain');

                // Custom domain
                enableCustomDomainControl.valueChanges.subscribe(value => {
                    if (value) {
                        customDomainControl.setValidators([
                            Utils.conditionalValidator(() => value, Validators.required)
                        ]);
                    } else {
                        customDomainControl.clearValidators();
                    }
                    customDomainControl.updateValueAndValidity();
                });

                const emailCarrierControl = this.form.get('emailCarrier');
                const emailHostControl = this.form.get('emailHost');
                const emailPortControl = this.form.get('emailPort');
                const emailUsernameControl = this.form.get('emailUsername');

                emailCarrierControl.value === 'smtp' ? this.setSmtpControls(true) : this.setSmtpControls(false);
                emailCarrierControl.valueChanges.subscribe(value => {
                    if (value === 'smtp') {
                        this.setSmtpControls(true);
                        emailHostControl.setValidators([Validators.required]);
                        emailPortControl.setValidators([Validators.required]);
                        emailUsernameControl.setValidators([Validators.required]);
                    } else {
                        this.setSmtpControls(false);
                        emailHostControl.clearValidators();
                        emailPortControl.clearValidators();
                        emailUsernameControl.clearValidators();
                    }
                    emailHostControl.updateValueAndValidity();
                    emailPortControl.updateValueAndValidity();
                    emailUsernameControl.updateValueAndValidity();
                });

                const sameSiteCookieRestrictionValue = this.form.get('sameSiteCookieRestriction').value;
                if (sameSiteCookieRestrictionValue) {
                    this.currentProvider = this.sameSiteCookieNames[sameSiteCookieRestrictionValue];
                } else {
                    this.currentProvider = this.sameSiteCookieNames.LAX;
                }
            })
        ).subscribe();
    }

    setSmtpControls(setState: boolean) {
        this.setState([
            this.form.get('emailHost'),
            this.form.get('emailPort'),
            this.form.get('emailUsername'),
            this.form.get('emailPassword'),
            this.form.get('emailFrom'),
            this.form.get('emailPersonalName')
        ], setState);
    }

    save(oldSettings: GlobalSettings) {
        if (this.form.status === 'VALID') {
            this.update(oldSettings);
        }
    }

    saveAndCheckSSO(oldSettings: GlobalSettings) {
        if (this.form.status === 'VALID' && this.form.get('samlEnabled').value) {
            this.update(oldSettings);
            const checkURLLink = encodeURI(this.assertionConsumerUrl + '/check?workspace=' + this.form.get('workspace').value);
            window.open(checkURLLink, '_blank');
        }
    }

    private update(oldSettings: GlobalSettings) {
        const model = this.form.getRawValue();
        const newSettingsO = zip(Utils.fileToModel(model.favicon), Utils.fileToModel(model.logo), Utils.fileToModel(model.logoDark))
            .pipe(map(([favicon, logo, logoDark]) => {
                const newSettings: GlobalSettings = {
                    allowedIPs: model.allowedIPs,
                    emailSettings: {
                        custom: model.emailCustom,
                        host: model.emailHost,
                        port: model.emailPort,
                        username: model.emailUsername,
                        from: model.emailFrom,
                        personalName: model.emailPersonalName,
                        password: model.emailPassword,
                        carrier: model.emailCarrier,
                        senderEmail: model.senderEmail
                    },
                    emailTemplate: {
                        title: model.emailTitle,
                        content: model.emailContent,
                        template: model.emailTemplate
                    },
                    customDomain: {
                        enabled: model.enableCustomDomain,
                        domain: model.customDomain
                    },
                    pageSettings: {
                        darkModeEnabled: model.enableDarkMode,
                        pageHeader: {
                            customHeader: model.customHeader,
                            color: model.customHeaderColor,
                            colorDarkMode: model.customHeaderColorDarkMode,
                            backgroundColor: model.customHeaderBackgroundColor,
                            backgroundColorDarkMode: model.customHeaderBackgroundColorDarkMode,
                            locationSelect: model.customHeaderLocationSelect,
                            customHeaderLinks: model.customHeaderLinks,
                            hideHeaderLinks: model.hideHeaderLinks,
                            showHeader: model.showHeader
                        },
                        customBody: {
                            showBackgroundImage: model.showBackgroundImage,
                            backgroundColor: model.customBodyBackgroundColor,
                            backgroundColorDarkMode: model.customBodyBackgroundColorDarkMode,
                            backgroundImage: model.customBodyBackgroundImage,
                            backgroundImageDarkMode: model.customBodyBackgroundImageDarkMode,
                        },
                        pageFooter: {
                            customFooter: model.customFooter,
                            color: model.customFooterColor,
                            colorDarkMode: model.customFooterColorDarkMode,
                            backgroundColor: model.customFooterBackgroundColor,
                            backgroundColorDarkMode: model.customFooterBackgroundColorDarkMode,
                            customFooterLinks: model.customFooterLinks,
                            showFooter: model.showFooter,
                        },
                        showSubscribe: model.showSubscribe,
                        analyticsID: model.analyticsID,
                        logo,
                        logoDark,
                        favicon
                    },
                    samlConfig: {
                        samlEnabled: model.samlEnabled,
                        loginUrl: model.loginUrl,
                        identifier: model.identifier,
                        logoutUrl: model.logoutUrl,
                        certificate: model.certificate,
                        workspace: model.workspace,
                        identityProvider: model.identityProvider
                    },
                    customCsp: model.customCsp,
                    sameSiteCookieRestriction: model.sameSiteCookieRestriction,
                    integrations: {
                        smartCheckList: model.enableSmartChecklist,
                        issueChecklist: model.enableIssueChecklist
                    },
                };
                return newSettings;
            }));

        this.globalSettings.update(
            oldSettings,
            newSettingsO,
            this.bindCustomDomainComponentError()
        ).subscribe();
    }

    private bindCustomDomainComponentError() {
        return this.customDomainComponent ? this.customDomainComponent.changeControlValidity.bind(this.customDomainComponent) : undefined;
    }

    saveButtonVisible() {
        return this.tab !== 'actions' && this.tab !== 'permissions' && !this.isWorkspaceTab();
    }

    isWorkspaceTab() {
        return this.tab === 'samlConfig' && this.samlConfigTab === 'workspace';
    }

    saveButtonDisabled(): boolean {
        return !this.form.valid;
    }

    workspaceConfigDisabled(): boolean {
        return !this.form.valid || this.form.get('samlEnabled').value === false;
    }

    restoreDefaultEmailTemplate() {
        this.app.restoreDefaultEmailTemplate()
            .subscribe(result => {
                this.form.get('emailTitle').setValue(result.emailTemplate.title);
                this.form.get('emailContent').setValue(result.emailTemplate.content);
                this.form.get('emailTemplate').setValue(result.emailTemplate.template);
            });
    }

    confirmRestore() {
        this.jira.showDialog({
                key: 'confirm-delete',
                customData: {
                    type: 'restore',
                    title: 'Restore all default values?',
                }
            }, (result) => {
                if (result.deleted) {
                    this.resetImageInputFields();
                    this.loader.fullscreen(true);
                    this.restoreDefaultPageSettings();
                    this.loader.fullscreen(false);

                } else {
                    this.loader.fullscreen(false);
                }
            }
        );
    }

    resetImageInputFields() {
        this.pageCustomizationComponent.resetImageUploadInput({logo: null}, 'logo');
        this.pageCustomizationComponent.resetImageUploadInput({logoDark: null}, 'logoDark');
        this.pageCustomizationComponent.resetImageUploadInput({favicon: null}, 'favicon');
    }

    restoreDefaultPageSettings() {
        this.app.restoreDefaultPageSettings()
            .subscribe(result => {
                this.form.get('enableDarkMode').setValue(result.pageSettings.darkModeEnabled);
                this.form.get('hideHeaderLinks').setValue(result.pageSettings.pageHeader.hideHeaderLinks);
                this.form.get('showHeader').setValue(result.pageSettings.pageHeader.showHeader);
                this.form.get('showSubscribe').setValue(result.pageSettings.showSubscribe);
                this.form.get('customHeader').setValue(result.pageSettings.pageHeader.customHeader);
                this.form.get('customHeaderColor').setValue(result.pageSettings.pageHeader.color);
                this.form.get('customHeaderColorDarkMode').setValue(result.pageSettings.pageHeader.colorDarkMode);
                this.form.get('customHeaderBackgroundColor').setValue(result.pageSettings.pageHeader.backgroundColor);
                this.form.get('customHeaderBackgroundColorDarkMode').setValue(result.pageSettings.pageHeader.backgroundColorDarkMode);
                this.form.get('customHeaderLocationSelect').setValue(result.pageSettings.pageHeader.locationSelect);
                this.form.get('customHeaderLinks').setValue(result.pageSettings.pageHeader.customHeaderLinks);
                this.form.get('showBackgroundImage').setValue(result.pageSettings.customBody.showBackgroundImage);
                this.form.get('customBodyBackgroundColor').setValue(result.pageSettings.customBody.backgroundColor);
                this.form.get('customBodyBackgroundColorDarkMode').setValue(result.pageSettings.customBody.backgroundColorDarkMode);
                this.form.get('customBodyBackgroundImage').setValue(result.pageSettings.customBody.backgroundImage);
                this.form.get('customBodyBackgroundImageDarkMode').setValue(result.pageSettings.customBody.backgroundImageDarkMode);
                this.form.get('showFooter').setValue(result.pageSettings.pageFooter.showFooter);
                this.form.get('customFooter').setValue(result.pageSettings.pageFooter.customFooter);
                this.form.get('customFooterColor').setValue(result.pageSettings.pageFooter.color);
                this.form.get('customFooterColorDarkMode').setValue(result.pageSettings.pageFooter.colorDarkMode);
                this.form.get('customFooterBackgroundColor').setValue(result.pageSettings.pageFooter.backgroundColor);
                this.form.get('customFooterBackgroundColorDarkMode').setValue(result.pageSettings.pageFooter.backgroundColorDarkMode);
                this.form.get('customFooterLinks').setValue(result.pageSettings.pageFooter.customFooterLinks);
                this.form.get('logo').setValue(Utils.modelToFile(result.pageSettings.logo));
                this.form.get('logoDark').setValue(Utils.modelToFile(result.pageSettings.logoDark));
                this.form.get('favicon').setValue(Utils.modelToFile(result.pageSettings.favicon));
                this.form.get('analyticsID').setValue(result.pageSettings.analyticsID);
            });
    }

    deleteAll() {
        this.shareActions.deleteAllShares();
    }

    get count() {
        return this.form.get('count');
    }

    migrateLite() {
        this.shareActions.migrateLite().subscribe();
    }

    isAccessInvalid() {
        return this.isInvalid('allowedIPs');
    }

    isSamlConfigInvalid() {
        return this.form.get('samlEnabled').value
            && this.isAnyInvalid('loginUrl', 'identifier', 'workspace', 'certificate');
    }

    isCustomEmailInvalid() {
        return this.form.get('emailCarrier').value === 'smtp'
            && this.isAnyInvalid('emailHost', 'emailPort', 'emailUsername');
    }

    isAnyEmailTemplateInvalid() {
        return this.isAnyInvalid('emailTitle', 'emailContent', 'emailTemplate');
    }

    isCustomDomainInvalid() {
        return this.isInvalid('customDomain');
    }

    isPageCustomizationInvalid() {
        return false;
    }

    private isAnyInvalid(...args: any[]): boolean {
        for (const arg of args) {
            if (this.isInvalid(arg)) {
                return true;
            }
        }
        return false;
    }

    private isInvalid(name: string): boolean {
        const control = this.form.get(name);
        return this.isTouchedOrDirty(control) && control.invalid;
    }

    private isTouchedOrDirty(control: AbstractControl) {
        return control.dirty || control.touched;
    }

    changeTab(tab: string, proOnly = false) {
        if (this.shouldMarkCustomEmailAsTouched()) {
            this.markCustomEmailAsTouched();
        }
        if (!proOnly) {
            this.tab = tab;
        }
    }

    private shouldMarkCustomEmailAsTouched(): boolean {
        return this.tab === 'customEmail'
            && this.form.get('emailCarrier').value === 'smtp';
    }

    private markCustomEmailAsTouched() {
        this.form.get('emailHost').markAsTouched();
        this.form.get('emailPort').markAsTouched();
        this.form.get('emailUsername').markAsTouched();
    }

    changeSAMLConfigTab(tab: string) {
        this.samlConfigTab = tab;
    }

    private setState(control: AbstractControl[], enabled: boolean, opts?: {
        onlySelf?: boolean;
        emitEvent?: boolean;
    }) {
        for (const c of control) {
            if (enabled) {
                c.enable(opts);
            } else {
                c.disable(opts);
            }
        }
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }
}
